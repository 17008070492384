import React from "react";
import NavBar from "../../components/navBar";
import SocialMediaLinks from "../../components/socialMediaLinks";
import MapComponent from "../../components/mapComponent";
import Footer from "../../components/footer";
import ContactUs from "../../components/contactUs";
import Header from "../../components/header";
import FooterNew from "../../components/footerNew";

export default class ContactUsPage extends React.Component {
  render() {
    return (
      <React.Fragment>
        {/* <NavBar></NavBar> */}
        <Header />
        <ContactUs />
        <MapComponent />
        <SocialMediaLinks />
        {/* <Footer /> */}
        <FooterNew />
      </React.Fragment>
    );
  }
}
