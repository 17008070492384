import styles from "./styles.module.css";
import StoreButton from "../storebutton";
import topImg from "../../assets/group_409.png";
import rectangle from "../../assets/rectangle_17.png";
import rectangle18 from "../../assets/rectangle_18.png";
import rectangle13 from "../../assets/rectangle_13.png";
import rectangle19 from "../../assets/rectangle_19.png";
import rectangle16 from "../../assets/rectangle_16.png";
import rectangle20 from "../../assets/rectangle_20.png";

import google_play_badge from "../../assets/google-play-badge.png";
import apple_store_badge from "../../assets/apple-store-badge.png";

export default function DownloadBlock() {
  const storeButtons = ["APP_STORE", "PLAY_STORE"];
  return (
    <div className={styles.outer_container}>
      <div className={styles.top_part}>
        <img className={styles.top_img} src={topImg} />
      </div>
      <div className={styles.container}>
      <img className={styles.rectangle} src={rectangle} />
      <img className={styles.rectangle18} src={rectangle18} />
      <img className={styles.rectangle13} src={rectangle13} />
      <img className={styles.rectangle19} src={rectangle19} />
      <img className={styles.rectangle16} src={rectangle16} />
      <img className={styles.rectangle20} src={rectangle20} />
        <div className={styles.flex1}>
          <div className={styles.flex1_content}>
            <div className={styles.flex1_content_heading}>
              {" "}
              The Next Start.{" "}
            </div>
            <div className={styles.flex1_content_heading}>
            It begins here.
            </div>
            <div className={styles.flex1_content_description}>
              Download the Dr.Polaris app and learn on the go. A Super doc after all must be prepared at
all times.
            </div>
          </div>
        </div>
        <div className={styles.flex2}>
          <div className={styles.flex2_content}>
            {/* <div className={styles.download_title}>
              <div>Download Links</div>
            </div> */}
            <div className={styles.store_button_container}>
              <div className={styles.app_store}>
                <a href="https://apps.apple.com/mr/app/dr-polaris/id1546537581" target="_blank">
                <img
                  className={styles.store_icon}
                  src={apple_store_badge}
                ></img>
                </a>
              </div>
            </div>
            <div className={styles.store_button_container}>
              <div className={styles.app_store}>
                <a href="https://play.google.com/store/apps/details?id=com.drpolaris" target="_blank">
                <img
                  className={styles.app_icon}
                  src={google_play_badge}
                ></img>
                </a>
              </div>
            </div>
            {/* {storeButtons.map((storeName) => {
              return (
                <div className={styles.store_button_container}>
                  <StoreButton type={storeName} />
                </div>
              );
            })} */}
          </div>
        </div>
      </div>
    </div>
  );
}
