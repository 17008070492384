import { Link, NavLink } from "react-router-dom";
import "../../assets/css/mainStyle.css";
import footerLogo from "../../assets/images/ftrLogo.svg";

export default function FooterNew() {
  return (
    <div className="footer">
      <div className="container">
        <img src={footerLogo} alt="" />
        <ul>
          <li>
            <a href={`https://drpolaris.com/privacypolicy/index.html`}>Privacy policy</a>
          </li>
          <li>
            <a href={`https://drpolaris.com/termsandconditions/index.html`}>Terms and Conditions</a>
          </li>
          <li>
            <a href={`https://drpolaris.com/cookiepolicy/index.html`}>Cookie policy</a>
          </li>
          <li>
            <a href={`https://drpolaris.com/subscriptionagreement/index.html`}>
              Subscription Agreement
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
}
