import styles from "./styles.module.css";
import Iframe from "react-iframe";
import email_icon from "../../assets/mail.png";

export default function MapComponent() {
  return (
    <div className={styles.container}>
      <div className={styles.map_frame}>
        <Iframe
          url="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d994647.6499228344!2d74.73108247620739!3d13.140790206905118!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ba35bcf7928b127%3A0x464dc8a52846da8d!2sDR.POLARIS%20-%20Medical%20Learning%20App!5e0!3m2!1sen!2sin!4v1640590648641!5m2!1sen!2sin"
          width="100%"
          height="100%"
          id="myId"
          className={styles.map_frame}
          position="relative"
        />
        <div className={styles.map_info}>
          <div className={`${styles.main_adress}`}>
            <button className={styles.adress_ui}>
              <img className={styles.adress_icon} src={email_icon} />
            </button>
            Address
          </div>
          <div className={`${styles.address_name}`}>
            Dr Polaris Private Ltd, 18/260 A, Valiyapurayil House, Edathuruthy,
            Thrissur, Kerala - 680703
          </div>
        </div>
      </div>
    </div>
  );
}
