import styles from "./styles.module.css";
import ReactPlayer from "react-player";
import isMobile from "is-mobile";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import Swiper from "swiper/bundle";
import img1 from "../../assets/group_420.png";
import closeIcon from "../../assets/close.png";
import React, { useState } from "react";
import { useModal } from "react-hooks-use-modal";

export default function YoutubeVideoGallery() {
  const youtubeVideos = [
    [
      "https://www.youtube.com/embed/MeQouJ6-UNM",
      "https://www.youtube.com/embed/6H5aau77nJA",
      "https://www.youtube.com/embed/gni_t2f2vPY",
      "https://www.youtube.com/embed/-l6XvmRkD5I",
    ],
    [
      "https://www.youtube.com/embed/F301VP8nhG0",
      "https://www.youtube.com/embed/UdgervPrUTw",
      "https://www.youtube.com/embed/gZc6qlyoZjI",
    ],
  ];

  const [videoOnModal, setVideoOnModal] = useState("");

  const [Modal, open, close, isOpen] = useModal("root", {
    preventScroll: true,
    closeOnOverlayClick: true,
  });

  const onModalOpen = (url: string) => {
    setVideoOnModal(url);
    open();
  };

  const youtube = youtubeVideos.flatMap((videoLink) => videoLink);

  const swiper = new Swiper(".swiper_yt", {
    // If we need pagination
    pagination: {
      el: ".swiper_yt-pagination",
      clickable: true,
    },

    navigation: {
      nextEl: ".swiper-button-next-yt",
      prevEl: ".swiper-button-prev-yt",
    },
  });
  return (
    <div className={styles.container}>
      {/* <iframe width="420" height="315" src="https://www.youtube.com/watch?v=MeQouJ6-UNM" allowFullScreen={true}></iframe> */}

      <img className={styles.top_img} src={img1}></img>
      <div className={styles.header}>Demo videos</div>
      <div className={styles.ytb_container}>
        <div className="swiper_yt">
          {isMobile() && (
            <div className="swiper-wrapper">
              {youtube.map((array1, index) => {
                return (
                  <div
                    className="swiper-slide"
                    key={index}
                    style={{"display": "inline-flex"}}
                    onClick={() => onModalOpen(array1)}
                  >
                    <ReactPlayer
                      style={{ pointerEvents: "none" }}
                      width={isMobile() ? '100%' : 380}
                      height={isMobile() ? 180 : 200}
                      url={array1}
                    />
                  </div>
                );
              })}
            </div>
          )}
          {!isMobile() && (
            <div className="swiper-wrapper">
              {youtubeVideos.map((array1, index) => {
                return (
                  <div className="swiper-slide" key={index}>
                    <div className="db_cont" key={index}>
                      {array1.map((ybLink, index) => {
                        return (
                          <div
                            className="vieo_deck"
                            onClick={() => onModalOpen(ybLink)}
                          >
                            <ReactPlayer
                              style={{ pointerEvents: "none" }}
                              width={isMobile() ? 280 : 340}
                              height={isMobile() ? 180 : 200}
                              url={ybLink}
                            />
                          </div>
                        );
                      })}
                    </div>
                  </div>
                );
              })}
            </div>
          )}

          <div className="pagination_container-yt">
            <div className="swiper_yt-pagination"></div>
          </div>
          {!isMobile() && (
            <React.Fragment>
              <div className="swiper-button-prev-yt"></div>
              <div className="swiper-button-next-yt"></div>
            </React.Fragment>
          )}
        </div>
      </div>

      <Modal>
        <div className={styles.popup_container}>
          <img
            className={styles.closeModal}
            src={closeIcon}
            onClick={() => close()}
          />
          <ReactPlayer
            // style={{ pointerEvents: "none" }}
            playing={true}
            width={isMobile() ? 320 : 990}
            height={isMobile() ? 250 : 550}
            url={videoOnModal}
            controls = {true}
          />
        </div>
      </Modal>
    </div>
  );
}
