import styles from "./styles.module.css";
import app from "../../assets/6.Website Home Screen Mobile.png";
import StoreButton from "../storebutton";
import img2 from "../../assets/rectangle_2.png";
import img1 from "../../assets/group.png";
import rectagle from "../../assets/rectangle_244.png";
import book_open from "../../assets/book_open.png";
import book from "../../assets/book.png";
import user_check from "../../assets/user_check.png";
import video from "../../assets/video.png";
import google_play_badge from "../../assets/google-play-badge.png";
import apple_store_badge from "../../assets/apple-store-badge.png";

export default function Stats() {
  return (
    <div className={styles.container}>
      <div className={styles.flex1}>
        <div>Start learning with</div>
        <div>Dr.Polaris</div>
        <div className={styles.stats_container}>
          <img className={styles.book_open} src={book_open} />
          <img className={styles.rectagle1} src={rectagle} />
          <img className={styles.rectagle2} src={rectagle} />
          <img className={styles.img1} src={img1} />
          <img className={styles.rectagle3} src={rectagle} />
          <img className={styles.img2} src={img2} />
          <div className={styles.stat1}>
            <div className={styles.number1}>15</div>
            <div className={`${styles.stat_name} ${styles.poppinsText}`}>Subjects</div>
          </div>
          <img className={styles.book} src={book} />
          <div className={styles.stat2}>
            <div className={styles.number2}>1950+</div>
            <div className={`${styles.stat_name} ${styles.wrapp} ${styles.poppinsText}`}>Topics Covered</div>
          </div>
          <img className={styles.user_check} src={user_check} />
          <div className={styles.stat3}>
            <div className={styles.number2}>40+</div>
            <div className={`${styles.stat_name} ${styles.poppinsText}`}>Educators</div>
          </div>
          <img className={styles.video} src={video} />
          <div className={styles.stat4}>
            <div className={styles.number2}>1200+</div>
            <div className={`${styles.stat_name} ${styles.wrapp} ${styles.poppinsText}`}>Hours of Videos</div>
          </div>
          <img className={styles.rectagle4} src={rectagle} />
        </div>
      </div>
      <div className={styles.flex2}>
        <img className={styles.app_view} src={app}></img>
        <div className={styles.download_tile}>
          <div className={styles.download_tile_title}>
            Download Dr.Polaris app now
          </div>
          <div className={styles.button_container}>
            {/* <StoreButton type="APP_STORE" /> */}
            {/* <StoreButton type="PLAY_STORE" /> */}
            <div className={styles.app_store}>
            <a href="https://apps.apple.com/mr/app/dr-polaris/id1546537581" target="_blank">
              <img
                className={styles.store_icon}
                src={apple_store_badge}
              ></img>
              </a>
            </div>

            <div className={styles.play_store} >
              <a href="https://play.google.com/store/apps/details?id=com.drpolaris" target="_blank">
              <img
                className={styles.app_icon}
                src={google_play_badge}
              ></img>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
