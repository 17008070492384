import styles from "./styles.module.css";
import car from "../../assets/group_322.png";
import ic_ophthalmology from "../../assets/ic_ophthalmology_2.png";
import ic_pathology from "../../assets/ic_pathology.png";
import isMobile from "is-mobile";
import React from "react";
import img2 from "../../assets/rectangle_2.png";
import img1 from "../../assets/group_2.png";

export default function About() {
  return (
    <div className={styles.container}>
      <img className={styles.img1} src={img1} />
      <img className={styles.img2} src={img2} />
      <div className={styles.header}>Dr.Polaris</div>
      <div className={styles.description}>
        <div>
          CBME has been the best thing that happened to medical education in the country as it takes
          our learning to a whole other level. But everything comes with a cost, right? The
          execution has been difficult - but not anymore, we provide a total solution to crack your
          CBME based Medical learning.
        </div>
        {/* <div>
          We are a medical learning and resource platform, and will address the
          learning needs of medical students and enable advancement in learning
          and knowledge sharing for the medical community.
        </div>

        <div>
          Our first offering is a unique app-based solution which will help MBBS
          students grasp concepts better through video lectures, case scenarios,
          mentorship, live discussions, notes and workbooks.
        </div>
        <div>
          The outcome-based learning approach will not only ensure that students
          understand and retain concepts for life but will also enable them to
          prepare for their MBBS exams, next and other entrance exams.
        </div> */}
      </div>
      {!isMobile() && (
        <React.Fragment>
          <img className={styles.first_image} src={ic_ophthalmology}></img>
          <img className={styles.sec_image} src={car}></img>
          <img className={styles.third_image} src={ic_pathology}></img>
        </React.Fragment>
      )}
    </div>
  );
}
