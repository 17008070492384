import React from "react";
import "../../assets/css/mainStyle.css";
import FooterNew from "../../components/footerNew";
import Header from "../../components/header";
import abtBnrImg from "../../assets/images/abtBnrImg.svg";
import abtBnr from "../../assets/images/abtBnr.png";
import abtBnrImgLft from "../../assets/images/abtBnrImgLft.png";
import abtBnrImgRgt from "../../assets/images/abtBnrImgRgt.png";
import characters from "../../assets/images/Characters.png";
import mblImg from "../../assets/images/mblImg.svg";
import valuesIcon1 from "../../assets/images/valuesIcon1.svg";
import valuesIcon2 from "../../assets/images/valuesIcon2.svg";
import valuesRight from "../../assets/images/valuesRight.png";
import valuesLeft from "../../assets/images/valuesLeft.png";
import valuesIcon3 from "../../assets/images/valuesIcon3.svg";
import valuesIcon4 from "../../assets/images/valuesIcon4.svg";
import logobig from "../../assets/images/logobig.png";
import ourVision from "../../assets/images/ourVision.png";
import ourMission from "../../assets/images/ourMission.png";
import founderLeftBg from "../../assets/images/founder-leftBg.png";
import ceo from "../../assets/images/ceo.png";
import director from "../../assets/images/director.png";
import founderRightBg from "../../assets/images/founder-rightBg.png";

export default class AboutUsPage extends React.Component {
  render() {
    return (
      <React.Fragment>
        <Header />

        {/* <div className="abtBnr">
          <img src={abtBnrImg} alt="" className="bnrBg" />
          <div className="container">
            <div className="abtBnr__box">
              <h1>About Dr Polaris</h1>
              <img src={abtBnr} alt="" />
            </div>
          </div>
        </div> */}

        <div className="abtBnr">
          <img src={abtBnrImgLft} alt="" className="bnrBg lft" />
          <div className="container">
            <div className="abtBnr__box">
              <p>You don't need to take all the steps.</p>
              <h1>Just the NEXT one</h1>
            </div>
          </div>
          <img src={abtBnrImgRgt} alt="" className="bnrBg rgt" />
          <img src={characters} alt="" className="bnrBg main" />
        </div>

        <div className="abtText">
          <div className="container">
            <h2>About us</h2>
            <p>
              The medical profession is considered one of the most noble, the world over. Becoming a
              qualified doctor requires hard work, dedication and constant learning. What a student
              learns today is going to save someone’s life tomorrow. It is imperative that a student
              is well versed in the prescribed subjects and learns to apply the learning in real
              life scenarios. Moreover, the learning does not stop there. A doctor needs to
              constantly upgrade his knowledge and keep up with new developments in the medical
              field. Dr. Polaris will provide the support and resources required in this journey
            </p>
          </div>
        </div>

        <div className="abtImg text-center">
          <div className="container">
            <img src={mblImg} alt="" />
          </div>
        </div>

        <div className="valuesBg">
          <div className="container">
            <h3>Values</h3>
            <div className="valuesBg__Inbox">
              <div className="valuesBg__Inbox__row row1">
                <div className="box">
                  <img src={valuesIcon1} alt="" />
                  <h6>
                    Excellence in each and <br />
                    every task we do
                  </h6>
                </div>

                <div className="box">
                  <img src={valuesIcon2} alt="" />
                  <h6>
                    Pioneer path breaking <br />
                    solutions
                  </h6>
                </div>

                <div className="box colred" style={{ opacity: 0.9 }}></div>

                <img src={valuesRight} alt="" className="bgImg row1" />
              </div>

              <div className="valuesBg__Inbox__row row2">
                <img src={valuesLeft} alt="" className="bgImg row2" />

                <div className="box colred second"></div>

                <div className="box">
                  <img src={valuesIcon3} alt="" />
                  <h6>
                    Innovate and constantly <br />
                    strive to improve
                  </h6>
                </div>

                <div className="box">
                  <img src={valuesIcon4} alt="" />
                  <h6>
                    Customer centric approach <br />
                    to all our actions
                  </h6>
                </div>
              </div>
            </div>

            <p className="textBox">
              Our first offering is a learning platform for MBBS students. The app based unique
              solution will help students grasp concepts, enable them to ace their exams and prepare
              for higher learning entrance exams. The outcome-based approach will ensure that a
              student understands and retains concepts for life.
            </p>
          </div>
        </div>

        <div className="ourVisionSec">
          {/* <div className="container">
            <img src={logobig} alt="" className="logoBig" />
          </div> */}
          <div className="row visionRow">
            <div className="col-6 imgBox">
              <img src={ourVision} alt="" />
            </div>
            <div className="col-6 contBox">
              <div className="box">
                <h2>Our Vision</h2>
                <p>
                  To enable better health outcomes for the society by addressing learning and
                  knowledge needs of medical aspirants, students and the medical community world
                  over.
                </p>
              </div>
            </div>
          </div>
          <div className="row missionRow">
            <div className="col-6 contBox">
              <div className="box">
                <h2>Our Mission</h2>
                <p>
                  To create world class learning and knowledge sharing platforms with the best
                  resources, partnerships and technology.
                </p>
              </div>
            </div>
            <div className="col-6 imgBox">
              <img src={ourMission} alt="" />
            </div>
          </div>
        </div>

        <div className="howStartedSec">
          <img src={founderLeftBg} alt="" className="bgImg left" />

          <div className="founderSec">
            <div className="container">
              <h3>How it all started</h3>
              <p className="textBox">
                The team is headed by Dr. Sharook Mubarak who is the founder of Dr.Polaris. The
                dream of Dr.Polaris was seeded when he started his preparation for USMLE
                examinations and realized the vast gulf in the quality of learning which was a far
                match from what real life clinical situations demanded. The dream started to take
                shape after he cleared his USMLE examination and many MBBS students approached him
                for tips on how to learn and understand concepts better. With more students
                approaching him by the day, he decided to make the learning concept available to
                all. Mr.Sijil Mubarak who is an entrepreneur leads the operations of the
                organisation.
              </p>
            </div>

            <div className="founderSec__box">
              <div className="box">
                <img src={ceo} alt="" />
                <div className="desBox">
                  <h6>Dr. Sharook Mubarak</h6>
                  <div>Founder & CEO</div>
                </div>
              </div>
              <div className="box">
                <img src={director} alt="" />
                <div className="desBox">
                  <h6>Sijil Mubarak</h6>
                  <div>Executive Director</div>
                </div>
              </div>
            </div>

            <div className="textBoxBg">
              <p className="textBox">
                The dream is now becoming a reality made possible by an energetic team which has a
                blend of seasoned professionals and young talent.
              </p>
            </div>
          </div>
          <img src={founderRightBg} alt="" className="bgImg right" />
        </div>

        <div className="footerSocial">
          <div className="container">
            <div className="footerSocial__Box">
              <ul>
                <li>
                  <a href={"https://twitter.com/drpolaris_med/"} target={"_blank"}>
                    <i className="fa-brands fa-twitter"></i>
                  </a>
                </li>
                <li>
                  <a href={"https://www.instagram.com/drpolaris_med/"} target={"_blank"}>
                    <i className="fa-brands fa-instagram"></i>
                  </a>
                </li>
                <li>
                  <a href={"https://www.linkedin.com/company/drpolarismed"} target={"_blank"}>
                    <i className="fa-brands fa-linkedin"></i>
                  </a>
                </li>
                <li>
                  <a href={"https://www.facebook.com/drpolarismed/"} target={"_blank"}>
                    <i className="fa-brands fa-facebook-f"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <FooterNew />
      </React.Fragment>
    );
  }
}
