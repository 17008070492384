import { useEffect, useState } from "react";
import "./App.css";
import Home from "./pages/home";
import upArrow from "./assets/icons8-collapse-arrow-90.png";
import { MdOutlineKeyboardArrowUp } from "react-icons/md";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ContactUsPage from "./pages/contactUs";
import { render } from "react-dom";
import AboutUsPage from "./pages/aboutUs";

function App() {
  const [showButton, setShowButton] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.pageYOffset > 300) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    });
  }, []);

  // This function will scroll the window to the top
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // for smoothly scrolling
    });
  };

  return (
    <div className="App">
      <BrowserRouter basename="/">
        <div>
          <Routes>
            <Route key={"/"} path="/" element={<Home />} />
            <Route key={"/contact_us"} path="/contact_us" element={<ContactUsPage />} />
            <Route key={"/about_us"} path="/about_us" element={<AboutUsPage />} />
          </Routes>
        </div>
      </BrowserRouter>
    </div>
  );
}

export default App;
