import styles from "./styles.module.css";
import car from "../../assets/dr_3.png";
import img1 from "../../assets/group_2.png";
import img2 from "../../assets/rectangle_5.png";
import StoreButton from "../storebutton";
// import landing_page_gif from "../../assets/2.Home Banner.gif";
import google_play_badge from "../../assets/google-play-badge.png";
import apple_store_badge from "../../assets/apple-store-badge.png";

import Character from "../../assets/Character.png";

export default function LandingFrame() {
  const storeButtons = ["APP_STORE", "PLAY_STORE"];
  return (
    <div className={styles.container}>
      <img className={styles.img1} src={img1}></img>
      <img className={styles.img2} src={img2}></img>
      <div className={styles.texts_container}>
        <div className={styles.info_container}>
          {/* <div className={styles.text1}>Everything has options,</div> */}
          <div className={styles.text2}>The Next Start for your CBME-based MBBS Learning</div>
          {/* <div className={styles.text3}>
            Concepts made easy with <b>Dr.Polaris</b>
          </div> */}
          <div className={styles.button_container}>
            {/* {storeButtons.map((storeName) => {
              return (
                <div className={styles.button_single_container}>
                  <StoreButton type={storeName} />
                </div>
              );
            })} */}
            <div className={styles.app_store}>
              <a href="https://apps.apple.com/mr/app/dr-polaris/id1546537581" target="_blank">
                <img className={styles.store_icon} src={apple_store_badge}></img>
              </a>
            </div>

            <div className={styles.play_store}>
              <a href="https://play.google.com/store/apps/details?id=com.drpolaris" target="_blank">
                <img className={styles.app_icon} src={google_play_badge}></img>
              </a>
            </div>
          </div>
        </div>
      </div>
      {/* <div className={styles.img_container}>
        <img className={styles.dr_image} src={Character}></img>
      </div> */}
      <img className={styles.dr_image} src={Character}></img>
    </div>
    // <div className={styles.container}>
    //   <img  className = {styles.ddd} src= {landing_page_gif}/>
    // </div>
  );
}
