import styles from "./styles.module.css";
import rectangle_13 from "../../assets/rectangle_13.png";
import rectangle_12 from "../../assets/rectangle_12.png";
import rectangle_15 from "../../assets/rectangle_15.png";
import ryan from "../../assets/Rayan.png";
import sanchit from "../../assets/Sanchit.png";
import sonal from "../../assets/Sonal.png";
import richa from "../../assets/Richa.png";
import isMobile from "is-mobile";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import Swiper from "swiper/bundle";

import React, { useEffect } from "react";

export default function MsgFromTutors() {
  const messageFromTutors = [
    { name: "Dr. Ryan Denis Fernandes", imge: ryan, topics: "General Surgery", message: `“General surgery is a vast subject comprising multiple specialties. At times the thought of
    reading and recollecting these topics can be daunting. Together, we will awaken your love
    for the subject with every session.”`},
    { name: "Dr. Sanchit Bajpai", imge: sanchit, topics: "ENT", message: `“I believe in helping students focus on “Seeing ENT rather than learning it.” The art of
    discovering answers rather than just formulating a diagnosis is the key to success in
    modern-day medicine.”`},
    { name: "Dr. Sonal Parihar", imge: sonal, topics: "OBG", message: `“Womb is the bed where life begins. The cyclical changes that occur in this mystical organ in
    a woman’s body is amazing and intriguing. The science behind the complexity, dynamic
    functionality and coordination of the uterus with related organs within a woman is
    “Gynaecology”. I have tried my best to deliver this complexity in the most simplified way”`},
    { name: "Dr. Richa Gupta", imge: richa, topics: "Physiology", message: `“Physiology is the subject dealing with how small molecules to organs work in integrated
    manner to maintain a healthy life”`}
  ];
  const swiper = new Swiper(".swiper", {
    // If we need pagination
    
    pagination: {
      el: isMobile() ? ".swiper-pagination_mobile" : ".swiper-pagination",
      clickable: true,
    },

    // Navigation arrows
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
  });

  return (
    <div className={styles.container}>
      <img className={styles.rec1} src={rectangle_13}></img>
      <img className={styles.rec2} src={rectangle_12}></img>

      <img className={styles.rec3} src={rectangle_13}></img>
      <img className={styles.rec4} src={rectangle_15}></img>

      <div className={styles.flex1}>
        <div className={styles.flex1_title}>
          Message from our tutors to students
        </div>
      </div>

      <div className="swiper">
        <div className="swiper-wrapper">
          {messageFromTutors.map(({name, imge, topics, message}, index) => {
            return (
              <div className="swiper-slide" key={index}>
                <div className={styles.flex2}>
                  <div className={styles.tutor_msg}>
                    {message}
                  </div>
                  <div className={styles.tutor_info}>
                    <div>
                      <img className={styles.tutor_img} src={imge} />
                    </div>
                    <div>
                      <div>{name}</div>
                      <div>{topics}</div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        <div className="pagination_container">
        {/* <div className="swiper-pagination_"></div> */}
          {(isMobile() && <div className="swiper-pagination_mobile"></div>) || (
            <div className="swiper-pagination"></div>
          )}
        </div>
      </div>
      {!isMobile() && (
        <React.Fragment>
          <div className="swiper-button-prev"></div>
          <div className="swiper-button-next"></div>
        </React.Fragment>
      )}

      {/* <div className="swiper-scrollbar"></div>

      {/* Dummy implementation to fit UI */}
    </div>
  );
}
