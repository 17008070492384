import styles from "./styles.module.css";
import rectangle_3 from "../../assets/rectangle_3.png";
import rectangle_7 from "../../assets/rectangle_7.png";
import React, { useEffect } from "react";

interface CompProps {
  index: number;
}
export default function UniqueFeaturesDev(props: CompProps) {
  return (
    <React.Fragment>
      {props.index % 2 === 0 && (
        <div className={styles.container}>
          <img className={styles.right_img} src={rectangle_3}></img>
        </div>
      )}

      {props.index % 2 != 0 && (
        <div className={styles.container}>
          <img className={styles.left_img} src={rectangle_7}></img>
        </div>
      )}
    </React.Fragment>
  );
}
