import styles from "./styles.module.css";
import frame from "../../assets/rectangle_186.png";

import frame_two from "../../assets/rectangle_186_4.png";
import frame_three from "../../assets/rectangle_186_2.png";
import frame_four from "../../assets/rectangle_186_5.png";
import frame_five from "../../assets/rectangle_186_3.png";
import rectangle_3 from "../../assets/rectangle_3.png";
import rectangle_11 from "../../assets/rectangle_11.png";
import rectangle_12 from "../../assets/rectangle_12.png";

import mbbs_gif from "../../assets/3.MBBS Lecture Videos.gif";
import animated_gif from "../../assets/4.Animated case senario.gif";
import story_gif from "../../assets/4.Story Based Learning.gif";
import dig_gif from "../../assets/5.Digital Board Session.gif";

// import Flashnote from "../../assets/Flashnote.png";

import Flashnote from "../../assets/Flash Note.gif";

import isMobile from "is-mobile";
import React from "react";
import UniqueFeaturesDev from "../uniqueFeatureDev";

export default function UniqueFeatures() {
  const featureList = [
    {
      img: mbbs_gif,
      title: "CBME Integrated Videos",
      description:
        "We have integrated pretty much everything as mentioned by NMC. Please don’t expect AETCOM, Yoga videos etc in our platform as we are not here to replace any medical colleges. Consider us as your supplementary partner not as virtual medical college.",
    },
    {
      img: animated_gif,
      title: "Animated case scenarios",
      description:
        "Nothing helps a student learn better than case simulations. The animated scenarios prepare a student for various exams and real-life cases. Reel life to real life.",
    },
    {
      img: story_gif,
      title: "Story based learning",
      description:
        "Everyone loves stories. The entertaining animation-based videos will help students correlate and remember concepts easily. Education and entertainment all rolled in one.",
    },
    {
      img: dig_gif,
      title: "Digital board sessions",
      description:
        "All-time favourite chalk and talk sessions delivered through vibrant digital presentations. The smart classes add life to lectures",
    },
    {
      img: Flashnote,
      title: "Flash notes",
      description:
        "A must have companion for exams. All high-yield points presented as a single pager note. Helps students to revise and recall in a flash.",
    },
  ];
  return (
    <div className={styles.outer_container}>
      <div className={styles.container}>
        <div className={styles.title}>Our unique features</div>
        {isMobile() && (
          <div className={styles.mobile_view_top_img}>
            <img className={styles.img11} src={rectangle_11}></img>
          </div>
        )}
        {!isMobile() && (
          <React.Fragment>
            <img className={styles.img11} src={rectangle_11}></img>
            <img className={styles.img12} src={rectangle_12} />
          </React.Fragment>
        )}
        {featureList.map((feature, index) => {
          return (
            <React.Fragment>
              <div className={styles.feature} key={index}>
                {isMobile() && (
                  <div className={styles.feature_flex1}>
                    <img className={styles.feature_img} src={feature.img} />
                  </div>
                )}
                {!isMobile() && index % 2 === 0 && (
                  <div className={styles.feature_flex1}>
                    <img className={styles.feature_img} src={feature.img} />
                  </div>
                )}

                <div className={styles.feature_flex2}>
                  <div className={styles.feature_title}> {feature.title}</div>
                  <div className={styles.feature_description}>{feature.description}</div>
                </div>
                {!isMobile() && index % 2 != 0 && (
                  <div className={styles.feature_flex1}>
                    <img className={styles.feature_img} src={feature.img} />
                  </div>
                )}
              </div>
              {index != 4 && <UniqueFeaturesDev index={index}></UniqueFeaturesDev>}
            </React.Fragment>
          );
        })}
      </div>
    </div>
  );
}
