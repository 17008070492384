import styles from "./styles.module.css";
import ANATOMY from "../../assets/images/anatomy.svg";
import PATHOLOGY from "../../assets/images/pathology.svg";
import ENT from "../../assets/images/ent.svg";
import GENERAL_MEDICINE from "../../assets/images/general_med.svg";
import PHYSIOLOGY from "../../assets/images/physiology.svg";
import PHARMACOLOGY from "../../assets/images/pharmacology.svg";
import OPHTHALMOLOGY from "../../assets/images/ophthalmology.svg"; //
import SURGERY from "../../assets/images/surgery.svg";
import BIOCHEMISTRY from "../../assets/images/biochemistry.svg";
import FORENSIC from "../../assets/images/forensic.svg";
import OBG from "../../assets/images/obg.svg";
import MICROBIOLOGY from "../../assets/images/microbiology.svg";
import PEDIATRICS from "../../assets/images/pediatrics.svg";
import COMMUNITY_MEDICINE from "../../assets/images/community.svg";
import ORTHOPEDICS from "../../assets/images/orthopedics.svg";

import React from "react";
import isMobile from "is-mobile";

import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import Swiper from "swiper/bundle";
import img2 from "../../assets/group_421.png";

export default function ChoiceOfLearing() {
  let list = [
    [
      {
        name: "Anatomy",
        icon: ANATOMY,
      },
      {
        name: "Pathology",
        icon: PATHOLOGY,
      },
      {
        name: "ENT",
        icon: ENT,
      },
      {
        name: "General Medicine",
        icon: GENERAL_MEDICINE,
      },
    ],
    [
      {
        name: "Physiology",
        icon: PHYSIOLOGY,
      },
      {
        name: "Pharmacology",
        icon: PHARMACOLOGY,
      },
      {
        name: "Ophthalmology",
        icon: OPHTHALMOLOGY,
      },
      {
        name: "General Surgery",
        icon: SURGERY,
      },
    ],
    [
      {
        name: "Biochemistry",
        icon: BIOCHEMISTRY,
      },
      {
        name: "Forensic",
        icon: FORENSIC,
      },
      {
        name: "Pediatrics",
        icon: PEDIATRICS,
      },
      {
        name: "OBG",
        icon: OBG,
      },
    ],
    [
      {
        name: "Microbiology",
        icon: MICROBIOLOGY,
      },
      {
        name: "Community Medicine",
        icon: COMMUNITY_MEDICINE,
      },
      {
        name: "Orthopedics",
        icon: ORTHOPEDICS,
      },
      {
        name: "",
        // icon: '',
      },
    ],
  ];

  if (isMobile()) {
    let requiredArr = [];
    for (let i = 0; i < list.length; i++) {
      let temp = [];
      for (let j = 0; j < list[i].length; j++) {
        temp.push(list[j][i]);
      }
      requiredArr.push(temp);
    }

    list = requiredArr;
  }

  const list2 = list.flatMap((videoLink) => videoLink);

  const swiper = new Swiper(".swiper_cl", {
    // If we need pagination
    pagination: {
      el: ".swiper_cl-pagination",
      clickable: true,
    },
  });
  return (
    <div className={styles.container}>
      {!isMobile() && <img className={styles.top_image} src={img2}></img>}
      <div className={styles.heading}>
        <div className={styles.main_heading}>Choice of learning</div>
        {!isMobile() && (
          <div className={styles.sub_heading}>
            Why pay for content one doesn’t need? Students can pick only the subjects or packs that
            they require. Nothing more.
          </div>
        )}
      </div>
      {!isMobile() && (
        <div className={styles.learning_list_container}>
          {list2.map(({ name, icon }, index) => (
            <div
              style={name === "" ? { border: "1px" } : {}}
              className={styles.learning_item}
              key={index}
            >
              <img
                style={name === "" ? { display: "none" } : {}}
                className={styles.learning_item_icon}
                src={icon}
              />
              <div className={styles.learning_item_name}>{name}</div>
            </div>
          ))}
        </div>
      )}

      {isMobile() && (
        <div className="swiper_cl">
          <div className="swiper-wrapper">
            {list.map((array1, index) => {
              return (
                <div className="swiper-slide" key={index}>
                  <div className="col_cont" key={index}>
                    {array1.map(({ name, icon }, index) => {
                      return (
                        <div
                          style={name === "" ? { border: "1px" } : {}}
                          className={styles.learning_item}
                          key={index}
                        >
                          <img
                            style={name === "" ? { display: "none" } : {}}
                            className={styles.learning_item_icon}
                            src={icon}
                          />
                          <div className={styles.learning_item_name}>{name}</div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              );
            })}
          </div>
          <div className="pagination_container">
            <div className="swiper_cl-pagination"></div>
          </div>
        </div>
      )}
    </div>
  );
}
