import { Link, NavLink } from "react-router-dom";
import "../../assets/css/mainStyle.css";
import mainLogo from "../../assets/images/logo.svg";

export default function Header() {
  return (
    <nav className="navbar navbar-expand-md">
      <div className="container">
        <Link to="/">
          <img src={mainLogo} alt="" />
        </Link>
        <button
          className="navbar-toggler collapsed"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav">
            <li className="nav-item">
              <NavLink
                to="/"
                className={({ isActive }) => (isActive ? "nav-link active" : "nav-link")}
              >
                Home
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                to="/about_us"
                className={({ isActive }) => (isActive ? "nav-link active" : "nav-link")}
              >
                About
              </NavLink>
            </li>
            {/* <li className="nav-item">
              <a className="nav-link" href="#">Notes</a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#">Blog</a>
            </li> */}
            <li className="nav-item">
              <NavLink
                to="/contact_us"
                className={({ isActive }) => (isActive ? "nav-link active" : "nav-link")}
              >
                Contact Us
              </NavLink>
            </li>
          </ul>
          {/* <button className="btn searchBtn"><i className="fa-solid fa-magnifying-glass"></i></button>
          <a href="" className="login-btn">Login/Sign Up</a>

          <div className="inputSearchBg">
            <input type="text" className="form-control inputSearch" />
          </div> */}
        </div>
      </div>
    </nav>
  );
}
