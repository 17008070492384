import styles from "./styles.module.css";
import { Button, Form, FormGroup, Label, Input } from "reactstrap";
// import phone from "../../assets/phone.png";
import email_icon from "../../assets/mail.png";
import img1 from "../../assets/group_2.png";
import img2 from "../../assets/rectangle_lite.png";

export default function ContactForm() {
  return (
    <div className={styles.container}>
      <img className={styles.img1} src={img1} />
      <img className={styles.img2} src={img2} />
      <div className={styles.flex1}>
        <div className={styles.contact_name}>Contact Us</div>
        <div className={styles.stats_container}>
          <div className={styles.stat1}>
            <div className={`${styles.stat_name}`}>
            Need Help? We are there to hear you
            </div>
          </div>

          {/* <div className={styles.stat2}>
            <div className={`${styles.number2}`}>
               <button className={styles.phone_ui}>
          <img className={styles.phone} src={phone} />
        </button>
              Phone
            </div>
            <div className={`${styles.address_name}`}>
              +911234567899
            </div>
          </div> */}

          <div className={styles.stat3}>
            <div className={`${styles.number2}`}>
            <button className={styles.phone_ui}>
          <img className={styles.email_icon} src={email_icon} />
        </button>
              Email
            </div>
            <div className={`${styles.address_name} ${styles.poppinsText}`}>
              info@drpolaris.com
            </div>
          </div>
        </div>
      </div>
      <div className={styles.div_component}>
        <Form className={styles.form}>
          <FormGroup>
            <Label className={styles.label} for="name">Name</Label>
            <Input
              className={styles.input}
              type="text"
              name="name"
              id="name"
              placeholder="Full name"
            />
          </FormGroup>
          <FormGroup>
            <Label className={`${styles.label} ${styles.form_margin}`} for="exampleEmail">Email</Label>
            <Input
              className={styles.input}
              type="email"
              name="email"
              id="exampleEmail"
              placeholder="Your Email"
            />
          </FormGroup>
          <FormGroup>
            <Label className={`${styles.label} ${styles.form_margin}`} for="exampleEmail">Mobile Number</Label>
            <Input
              className={styles.input}
              type="text"
              name="number"
              id="number"
              placeholder="Mobile Number"
            />
          </FormGroup>
          <FormGroup>
            <Label className={`${styles.label} ${styles.form_margin}`} for="examplePassword">Message</Label>
            <Input
              className={styles.message_input}
              type="text"
              name="message"
              id="message"
              placeholder="Type your message here"
            />
          </FormGroup>
          <Button onClick={(e) => { e.preventDefault(); }} className={styles.button} >Submit</Button>
        </Form>
      </div>
    </div>
  );
}
