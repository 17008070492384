import styles from "./styles.module.css";
import twitter from "../../assets/group_1715.png";
import instagram from "../../assets/group_1716.png";
import linkedIn from "../../assets/group_1717.png";
import facebook from "../../assets/group_1718.png";

export default function SocialMediaLinks() {
  const socialMediaLinks = [
    {
      name: "twitter",
      link: "https://twitter.com/drpolaris_med/",
      logo: twitter,
    },
    {
      name: "instagram",
      link: "https://www.instagram.com/drpolaris_med/",
      logo: instagram,
    },
    {
      name: "linkedIn",
      link: "https://www.linkedin.com/company/drpolarismed",
      logo: linkedIn,
    },
    {
      name: "facebook",
      link: "https://www.facebook.com/drpolarismed/",
      logo: facebook,
    },
  ];
  return (
    <div className={styles.container}>
      {/* <div className={styles.links_frame}>
        <div className={styles.inner_frame}>
          {socialMediaLinks.map(({ name, link, logo }) => {
            return <a href={link}><img className={styles.social_logo} src={logo}></img></a>;
          })}
        </div>
      </div> */}
      <div className="footerSocial">
        <div className="container">
          <div className="footerSocial__Box">
            <ul>
              <li>
                <a href={"https://twitter.com/drpolaris_med/"} target={"_blank"}>
                  <i className="fa-brands fa-twitter"></i>
                </a>
              </li>
              <li>
                <a href={"https://www.instagram.com/drpolaris_med/"} target={"_blank"}>
                  <i className="fa-brands fa-instagram"></i>
                </a>
              </li>
              <li>
                <a href={"https://www.linkedin.com/company/drpolarismed"} target={"_blank"}>
                  <i className="fa-brands fa-linkedin"></i>
                </a>
              </li>
              <li>
                <a href={"https://www.facebook.com/drpolarismed/"} target={"_blank"}>
                  <i className="fa-brands fa-facebook-f"></i>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}
