import React from "react";
import LandingFrame from "../../components/landingFrame";
import NavBar from "../../components/navBar";
import About from "../../components/about";
import DownloadBlock from "../../components/downloadBlock";
import ChoiceOfLearing from "../../components/choiceOfLearning";
import Stats from "../../components/stats";
import SocialMediaLinks from "../../components/socialMediaLinks";
import Footer from "../../components/footer";
import MsgFromTutors from "../../components/msgFromTutors";
import UniqueFeatures from "../../components/uniqueFeatures";
import isMobile from "is-mobile";
import YoutubeVideoGallery from "../../components/youtube";
import Header from "../../components/header";
import FooterNew from "../../components/footerNew";
export default class Home extends React.Component {
  render() {
    return (
      <React.Fragment>
        {/* <NavBar></NavBar> */}
        <Header />
        <LandingFrame></LandingFrame>
        <About></About>
        <UniqueFeatures />
        {!isMobile() && <DownloadBlock></DownloadBlock>}
        <ChoiceOfLearing></ChoiceOfLearing>
        <Stats></Stats>
        <YoutubeVideoGallery />
        <MsgFromTutors />
        <SocialMediaLinks />
        {/* <Footer /> */}
        <FooterNew />
      </React.Fragment>
    );
  }
}
